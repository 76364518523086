<template>
  <div class="grid flex-column">
    <div class="col">
      <Card>
        <template #content>
          <div class="grid align-items-end vertical-container">
            <div class="col-12 md:col-6">
              <div class="grid flex-column">
                <div class="col">
                  <h4>
                    <b>{{ $t("applications.applicationProgress") }}</b>
                  </h4>
                  <p>
                    {{ $t("applications.createNewApplication") }}
                  </p>
                </div>
                <div class="col">
                  <ProgressBar :value="progress" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col h-auto">
      <Card v-if="screenWidth > 1091">
        <template #content>
          <Steps :model="pages" :readonly="true" />
        </template>
      </Card>
      <router-view
        v-slot="{ Component }"
        @prevPage="prevPage($event)"
        @nextPage="nextPage($event)"
        class="pages"
        :key="$route.fullPath"
      >
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateApplications",
  data() {
    return {
      progress: 0,
      screenWidth: window.innerWidth,
      pages: [
        {
          label: this.$t("applications.information"),
          to: `/createApplication/information${
            this.$route.query.appId ? "?appId=" + this.$route.query.appId : ""
          }`,
        },
        {
          label: this.$t("applications.details"),
          to: `/createApplication/details${
            this.$route.query.appId ? "?appId=" + this.$route.query.appId : ""
          }`,
        },
        {
          label: this.$t("applications.downloadAndSign"),
          to: `/createApplication/downloadAndSign${
            this.$route.query.appId ? "?appId=" + this.$route.query.appId : ""
          }`,
        },
        {
          label: this.$t("applications.uploadSigned"),
          to: `/createApplication/uploadSigned${
            this.$route.query.appId ? "?appId=" + this.$route.query.appId : ""
          }`,
        },
      ],
    };
  },
  mounted() {
    this.$router.push(this.pages[0].to);
      window.addEventListener("resize",  () => {
      this.screenWidth = window.innerWidth
    })
  },
  methods: {
    nextPage(event) {
      if (this.pages[event.pageIndex + 1]) {
        this.$router.push(this.pages[event.pageIndex + 1].to);
      }
      this.progress = event.value;
    },

    prevPage(event) {
      if (this.pages[event.pageIndex - 1]) {
        this.$router.push(this.pages[event.pageIndex - 1].to);
      }
      this.progress = event.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-progressbar {
  border-radius: 100px;
}

.pages {
  margin-top: 1%;
}
</style>
